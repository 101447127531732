import React from 'react'
import PropTypes from 'prop-types'
import useSettingsQuery from '../hooks/useSettingsQuery'
import BackgroundFigure from '../../static/images/background-figure.svg'
import LanguageSelector from './LanguageSelector'
import useCategoriesState from '../hooks/useCategoriesState'
import useMenuListState from '../hooks/useMenuListState'

const Preloader = ({ siteLoaded }) => {
  const settings = useSettingsQuery()
  const categoriesState = useCategoriesState()
  const menuListState = useMenuListState()

  return (
    <div
      className={`preloader preloader--${
        siteLoaded ? 'closed' : 'opened'
      } preloader--${
        (categoriesState.categoriesOpened &&
          !categoriesState.listScrollStarted) ||
        (!categoriesState.categoriesOpened &&
          !menuListState.menuListScrollStarted)
          ? 'selector'
          : null
      }`}
    >
      <BackgroundFigure className="preloader__background" />
      <img src={settings.logoUrl} alt="logo" className="preloader__image" />
      <LanguageSelector short />
    </div>
  )
}

Preloader.propTypes = {
  siteLoaded: PropTypes.bool.isRequired
}

export default Preloader
