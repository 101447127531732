import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import '../styles/main.scss'
import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import Preloader from './Preloader'
import useSiteQuery from '../hooks/useSiteQuery'
import { preloaderHiding } from '../utils/helpers'
import useCssVariables from '../hooks/useCssVariables'
import useScrollStarted from '../hooks/useScrollStarted'

const Layout = ({ children }) => {
  const site = useSiteQuery()
  const dispatch = useDispatch()
  const [siteLoaded, setSiteLoaded] = useState(false)

  useCssVariables()

  useScrollStarted(dispatch)

  useEffect(() => {
    preloaderHiding(() => setSiteLoaded(true))
  }, [])

  return (
    <div>
      <Helmet title={site.siteMetadata.title}>
        <html lang="en" />
      </Helmet>
      <Preloader siteLoaded={siteLoaded} />
      <Header />
      {children}
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
