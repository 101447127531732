import { useStaticQuery, graphql } from 'gatsby'

const useMenuListQuery = () => {
  const contentObjects = useStaticQuery(graphql`
    query Content {
      allMenuContent {
        nodes {
          key
          value__2NdLang_
          value__3NdLang_
          value__4NdLang_
          value__mainLang_
        }
      }
    }
  `).allMenuContent.nodes

  const content = {}
  content.socials = {}

  contentObjects.forEach(item => {
    if (item.key.includes('SocialUrl')) {
      content.socials[item.key.split('SocialUrl')[0]] = item.value__mainLang_
    } else if (item.value__2NdLang_) {
      content[item.key] = [
        item.value__mainLang_,
        item.value__2NdLang_,
        item.value__3NdLang_,
        item.value__4NdLang_
      ]
    } else {
      content[item.key] = item.value__mainLang_
    }
  })

  return content
}

export default useMenuListQuery
