import { setTranslate, setAnimatedTranslate } from './helpers'

const swipeDetect = (ref, swipeLeftCb, swipeRightCb) => {
  let touchstartX = 0
  let touchendX = 0
  let currentPercent = 0
  let categoriesOpened = true
  const gestureZone = document.documentElement
  let currentXTranslate = Number(
    ref.current.style.transform
      .slice(11, ref.current.style.transform.length)
      .split('%')[0]
  )
  let scrollStarted = false
  let initialScrollPosition = window.pageYOffset
  let scrollCounter = 0

  const handleGesture = () => {
    currentPercent =
      (touchendX / gestureZone.offsetWidth) * 100 -
      (touchstartX / gestureZone.offsetWidth) * 100

    if (!scrollStarted && categoriesOpened && currentPercent >= 10) {
      swipeRightCb()
      categoriesOpened = false
      scrollStarted = false
    }

    if (
      !scrollStarted &&
      categoriesOpened &&
      currentPercent < 10 &&
      currentPercent > 0
    ) {
      setAnimatedTranslate(ref, 0, 0.3)
      categoriesOpened = true
      scrollStarted = false
    }

    if (!scrollStarted && !categoriesOpened && currentPercent < -10) {
      swipeLeftCb()
      categoriesOpened = true
      scrollStarted = false
    }

    if (
      !scrollStarted &&
      !categoriesOpened &&
      currentPercent > -10 &&
      currentPercent < 0
    ) {
      setAnimatedTranslate(ref, 100, 0.3)
      categoriesOpened = false
      scrollStarted = false
    }
  }

  gestureZone.addEventListener(
    'touchstart',
    event => {
      scrollStarted = false
      scrollCounter = 0
      currentXTranslate = Number(
        ref.current.style.transform
          .slice(11, ref.current.style.transform.length)
          .split('%')[0]
      )

      if (currentXTranslate === 0) {
        categoriesOpened = true
      } else if (currentXTranslate === 100) {
        categoriesOpened = false
      }

      touchstartX = event.changedTouches[0].screenX
    },
    false
  )

  let moveTimeout

  gestureZone.addEventListener(
    'touchmove',
    event => {
      currentPercent =
        (event.changedTouches[0].screenX / event.currentTarget.offsetWidth) *
          100 -
        (touchstartX / event.currentTarget.offsetWidth) * 100

      if (!scrollStarted && categoriesOpened && currentPercent > 0) {
        setTranslate(ref, currentPercent)
      }

      if (!scrollStarted && !categoriesOpened && currentPercent < 0) {
        setTranslate(ref, 100 + currentPercent)
      }

      if (scrollStarted && !categoriesOpened) {
        setAnimatedTranslate(ref, 100, 0.3)
        // scrollStarted = false
      }

      if (scrollStarted && categoriesOpened) {
        setAnimatedTranslate(ref, 0, 0.3)
        // scrollStarted = false
      }

      touchendX = event.changedTouches[0].screenX

      clearTimeout(moveTimeout)
      moveTimeout = setTimeout(() => {
        touchendX = event.changedTouches[0].screenX
        handleGesture()
      }, 1000)
    },
    false
  )

  window.addEventListener('scroll', event => {
    if (scrollCounter === 0) {
      initialScrollPosition = event.currentTarget.pageYOffset
      scrollCounter++
    }

    const scrollLength =
      initialScrollPosition > event.currentTarget.pageYOffset
        ? initialScrollPosition - event.currentTarget.pageYOffset
        : event.currentTarget.pageYOffset - initialScrollPosition

    if (scrollLength >= 100) {
      scrollStarted = true
    }
  })

  gestureZone.addEventListener(
    'touchcancel',
    event => {
      clearTimeout(moveTimeout)
      touchendX = event.changedTouches[0].screenX
      handleGesture()
    },
    false
  )

  gestureZone.addEventListener(
    'touchend',
    event => {
      clearTimeout(moveTimeout)
      touchendX = event.changedTouches[0].screenX
      handleGesture()
    },
    false
  )
}

export default swipeDetect
