import { useStaticQuery, graphql } from 'gatsby'

const useSiteQuery = () =>
  useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `).site

export default useSiteQuery
