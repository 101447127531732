export const getCookie = name => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const getMenuObject = menu => {
  const categoriesNodes = menu.allMenuCategories.nodes.sort(
    (object1, object2) => object1.Id - object2.Id
  )
  const subCategoriesNodes = menu.allMenuSubCategories.nodes.sort(
    (object1, object2) => object1.Id - object2.Id
  )
  const menuItemsNodes = menu.allMenuItems.nodes.sort(
    (object1, object2) => object1.Id - object2.Id
  )
  const menuItemsProps = menu.allMenuItemsProps.nodes.sort(
    (object1, object2) => object1.Id - object2.Id
  )
  const categories = categoriesNodes.map(item => {
    return {
      categoryName: [
        item.categoryName__mainLang_,
        item.categoryName__2NdLang_,
        item.categoryName__3NdLang_,
        item.categoryName__4NdLang_
      ]
    }
  })

  const subCategories = subCategoriesNodes.map(item => {
    const category = categories.find(
      categoriesItem => categoriesItem.categoryName[0] === item.categoryName
    )
    return {
      ...category,
      subCategoryName: [
        item.subCategoryName__mainLang_,
        item.subCategoryName__2NdLang_,
        item.subCategoryName__3NdLang_,
        item.subCategoryName__4NdLang_
      ]
    }
  })

  const menuItems = menuItemsNodes.map(item => {
    const subCategory = subCategories.find(
      subCategoriesItem =>
        subCategoriesItem.subCategoryName[0] === item.subCategoryName
    )

    const itemProps = menuItemsProps.find(prop => prop.Id === item.Id)

    return {
      ...subCategory,
      ...itemProps,
      heading: [
        item.heading__mainLang_,
        item.heading__2NdLang_,
        item.heading__3NdLang_,
        item.heading__4NdLang_
      ],
      text: [
        item.text__mainLang_,
        item.text__2NdLang_,
        item.text__3NdLang_,
        item.text__4NdLang_
      ]
    }
  })

  const newMenu = categories.map(item => {
    return {
      categoryName: item.categoryName,
      subCategories: subCategories
        .filter(
          filterItem => item.categoryName[0] === filterItem.categoryName[0]
        )
        .map(subCategory => {
          return {
            subCategoryName: subCategory.subCategoryName,
            menuItems: menuItems.filter(
              filterItem =>
                subCategory.subCategoryName[0] === filterItem.subCategoryName[0]
            )
          }
        })
    }
  })

  return newMenu
}

export const deleteSpaces = string => string.replace(/\s/g, '')

export const setImageObject = (allImages, imageName) => {
  return allImages.find(item => item.imageName === imageName)
}

export const preloaderHiding = cb => {
  let timerIsEnd = false
  setTimeout(() => {
    timerIsEnd = true
  }, 1000)
  if (document.readyState === 'complete') {
    if (timerIsEnd) {
      cb()
    }
    const interval = setInterval(() => {
      if (timerIsEnd) {
        clearInterval(interval)
        cb()
      }
    }, 100)
  } else {
    window.addEventListener('load', () => {
      if (timerIsEnd) {
        cb()
      }
      const interval = setInterval(() => {
        if (timerIsEnd) {
          clearInterval(interval)
          cb()
        }
      }, 100)
    })
  }
}

export const detectLanguage = () =>
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage

export const getLang = () => {
  const languages = {
    'uk-UA': 'ua',
    'ru-RU': 'ua'
  }

  return getCookie('userLanguage') || languages[detectLanguage()] || 'en'
}

export const checkIOSVersion = () => {
  const agent = window.navigator.userAgent
  const start = agent.indexOf('OS ')
  if (
    (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
    start > -1
  ) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'))
  }
  return 0
}

export const setTranslate = (currentRef, percent) => {
  currentRef.current.style.transform = `translateX(${percent}%)`
}

export const setAnimatedTranslate = (currentRef, percent, seconds = 0.3) => {
  const transitionString = `transform ${seconds}s ease`
  currentRef.current.style.transition = transitionString
  setTranslate(currentRef, percent)
  setTimeout(() => {
    currentRef.current.style.transition = 'none'
  }, 300)
}

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }

const preventDefault = e => {
  e.preventDefault()
}

const preventDefaultForScrollKeys = e => {
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
  return true
}

let supportsPassive = false
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        supportsPassive = true
      }
    })
  )
} catch (e) {
  ;() => false
}

const wheelOpt = supportsPassive ? { passive: false } : false

export const disableScroll = () => {
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
  window.addEventListener('DOMMouseScroll', preventDefault, false)
  window.addEventListener(wheelEvent, preventDefault, wheelOpt)
  window.addEventListener('touchmove', preventDefault, wheelOpt)
  window.addEventListener('keydown', preventDefaultForScrollKeys, false)
}

export const enableScroll = () => {
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
  window.removeEventListener('DOMMouseScroll', preventDefault, false)
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt)
  window.removeEventListener('touchmove', preventDefault, wheelOpt)
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
}
