import { useStaticQuery, graphql } from 'gatsby'

const useMenuListQuery = () =>
  useStaticQuery(graphql`
    query MenuListQuery {
      allMenuSubCategories {
        nodes {
          Id
          subCategoryName__4NdLang_
          subCategoryName__3NdLang_
          subCategoryName__2NdLang_
          subCategoryName__mainLang_
          categoryName
        }
      }
      allMenuCategories {
        nodes {
          Id
          categoryName__4NdLang_
          categoryName__3NdLang_
          categoryName__2NdLang_
          categoryName__mainLang_
        }
      }
      allMenuItems {
        nodes {
          Id
          subCategoryName
          heading__mainLang_
          heading__2NdLang_
          heading__3NdLang_
          heading__4NdLang_
          text__mainLang_
          text__2NdLang_
          text__3NdLang_
          text__4NdLang_
        }
      }
      allMenuItemsProps {
        nodes {
          Id
          price
          size
          itemName
        }
      }
    }
  `)

export default useMenuListQuery
