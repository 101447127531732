import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import useMenuListQuery from '../hooks/useMenuListQuery'
import useContentQuery from '../hooks/useContentQuery'
import { getMenuObject, deleteSpaces } from '../utils/helpers'
import useLanguageState from '../hooks/useLanguageState'
import useCategoriesState from '../hooks/useCategoriesState'
import useSwipe from '../hooks/useSwipe'
import { setCategoriesOpened } from '../redux/actions/categories'
import useSettingsQuery from '../hooks/useSettingsQuery'
import Arrows from '../../static/icons/arrows.svg'

const Categories = () => {
  const [isIOS, setIsIOS] = useState(false)
  const languageState = useLanguageState()
  const categoriesState = useCategoriesState()
  const dispatch = useDispatch()
  const menu = getMenuObject(useMenuListQuery())
  const content = useContentQuery()
  const categoriesFieldRef = useRef()
  const settings = useSettingsQuery()

  const openCategories = () => {
    dispatch(setCategoriesOpened(true))
  }

  const closeCategories = () => {
    dispatch(setCategoriesOpened(false))
  }

  useSwipe(categoriesFieldRef, openCategories, closeCategories, categoriesState)

  useEffect(() => {
    dispatch(setCategoriesOpened(settings.categoriesList === 'main'))
    setIsIOS(!!navigator.platform.match(/iPhone|iPod|iPad/))
  }, [])

  const handleScrollButtonClick = anchor => {
    const currentXTranslate = Number(
      categoriesFieldRef.current.style.transform
        .slice(11, categoriesFieldRef.current.style.transform.length)
        .split('%')[0]
    )

    if (currentXTranslate === 0) {
      document.querySelector(`#${anchor}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })

      if (isIOS) {
        window.scrollTo(0, window.pageYOffset - 81)
      }

      dispatch(setCategoriesOpened(false))
    }
  }

  return (
    <div className="categories">
      <button
        type="button"
        className="categories__button"
        onClick={() =>
          // eslint-disable-next-line prettier/prettier
          dispatch(setCategoriesOpened(!categoriesState.categoriesOpened))}
      >
        <Arrows
          className={`categories__button-icon categories__button-icon--close categories__button-icon--${
            categoriesState.categoriesOpened ? 'active' : 'disabled'
          }`}
        />
        {content.menuButtonName[languageState.currentLanguageIndex]}
        <Arrows
          className={`categories__button-icon categories__button-icon--open categories__button-icon--${
            !categoriesState.categoriesOpened ? 'active' : 'disabled'
          }`}
        />
      </button>
      <div
        ref={categoriesFieldRef}
        className={`categories__field categories__field--${
          categoriesState.categoriesOpened ? 'active' : 'disabled'
        }`}
      >
        <ul className="categories__list">
          {menu.map(category => (
            <li className="categories__list-item" key={Math.random()}>
              <button
                type="button"
                className="categories__category-button"
                onClick={() =>
                  // eslint-disable-next-line prettier/prettier
                  handleScrollButtonClick(deleteSpaces(category.categoryName[0]))}
              >
                {category.categoryName[languageState.currentLanguageIndex]}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Categories
