import { useStaticQuery, graphql } from 'gatsby'

const useMenuListQuery = () => {
  const settingsObject = useStaticQuery(graphql`
    query Settings {
      allMenuSettings {
        nodes {
          key
          value
        }
      }
    }
  `).allMenuSettings.nodes

  const settings = {}

  settingsObject.forEach(item => {
    if (item.key === 'languages') {
      settings.languages = item.value
        .split(', ')
        .map(language => language.toLowerCase())
    } else {
      settings[item.key] = item.value
    }
  })

  return settings
}

export default useMenuListQuery
