import React from 'react'
import useContentQuery from '../hooks/useContentQuery'
import LanguageSelector from './LanguageSelector'
import Facebook from '../../static/icons/facebook.svg'
import Instagram from '../../static/icons/instagram.svg'
import Tripadvisor from '../../static/icons/tripadvisor.svg'
import FooterBackground from '../../static/images/footer-bg.svg'
import FooterBackgroundDesktop from '../../static/images/footer-bg-desktop.svg'

const Footer = () => {
  const content = useContentQuery()

  return (
    <footer className="footer container">
      <FooterBackground className="footer__background footer__background--mobile" />
      <FooterBackgroundDesktop className="footer__background footer__background--desktop" />
      <div
        className={`footer__socials footer__socials--${
          Object.entries(content.socials).filter(item => item[1]).length
        }-links`}
      >
        {Object.entries(content.socials).map(social =>
          social[1] ? (
            <a
              key={Math.random()}
              href={social[1]}
              className="footer__social-link"
              rel="noreferrer"
              target="_blank"
            >
              {social[0] === 'facebook' ? (
                <Facebook className="footer__social-image" />
              ) : social[0] === 'instagram' ? (
                <Instagram className="footer__social-image" />
              ) : social[0] === 'tripadvisor' ? (
                <Tripadvisor className="footer__social-image" />
              ) : null}
            </a>
          ) : null
        )}
      </div>
      <LanguageSelector />
    </footer>
  )
}

export default Footer
