import React from 'react'
import Categories from './Categories'
import useSettingsQuery from '../hooks/useSettingsQuery'

const Header = () => {
  const settings = useSettingsQuery()
  return (
    <header className="header container">
      {settings.categoriesList !== 'disabled' ? <Categories /> : null}
    </header>
  )
}

export default Header
