import { useEffect } from 'react'
import swipeDetect from '../utils/swipeDetect'
import {
  setAnimatedTranslate,
  disableScroll,
  enableScroll
} from '../utils/helpers'

function useSwipe(ref, cbOpen, cbClose, categoriesState) {
  useEffect(() => {
    if (categoriesState.categoriesOpened) {
      disableScroll()
      window.scrollTo(0, window.pageYOffset)
      setAnimatedTranslate(ref, 0, 0.3)
    } else {
      enableScroll()
      setAnimatedTranslate(ref, 100, 0.3)
    }
  }, [categoriesState.categoriesOpened])

  useEffect(() => {
    swipeDetect(ref, cbOpen, cbClose)
  }, [])
}

export default useSwipe
