import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setLanguage } from '../redux/actions/language'
import useLanguage from '../hooks/useLanguage'
import useLanguageState from '../hooks/useLanguageState'
import useSettingsQuery from '../hooks/useSettingsQuery'

const LanguageSelector = ({ short }) => {
  const languageState = useLanguageState()
  const settings = useSettingsQuery()
  const dispatch = useDispatch()
  useLanguage(dispatch, settings.languages)

  const buttonHandler = event => {
    document.cookie = `userLanguage=${event.currentTarget.value}`
    dispatch(
      setLanguage(
        event.currentTarget.value,
        settings.languages.findIndex(
          language => language === event.currentTarget.value
        ) || 0
      )
    )
  }

  return settings.languages.length > 1 ? (
    <div className="language-selector">
      <div className="language-selector__section">
        {settings.languages.map((language, index) =>
          index <= 1 ? (
            <button
              key={Math.random()}
              type="button"
              onClick={buttonHandler}
              className={`language-selector__button language-selector__button--${
                languageState.currentLanguage === language ? 'active' : null
              }`}
              value={language}
            >
              {language}
            </button>
          ) : null
        )}
      </div>
      {!short && settings.languages.length > 2 ? (
        <div className="language-selector__section">
          {settings.languages.map((language, index) =>
            index > 1 ? (
              <button
                key={Math.random()}
                type="button"
                onClick={buttonHandler}
                className={`language-selector__button language-selector__button--${
                  languageState.currentLanguage === language ? 'active' : null
                }`}
                value={language}
              >
                {language}
              </button>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  ) : null
}

LanguageSelector.propTypes = {
  short: PropTypes.bool
}

LanguageSelector.defaultProps = {
  short: false
}

export default LanguageSelector
