import { useEffect } from 'react'
import { setLanguage } from '../redux/actions/language'
// import { getLang } from '../utils/helpers'

function useLanguage(dispatch) {
  useEffect(() => {
    dispatch(
      setLanguage('ua', 0)
      // setLanguage(
      //   getLang(),
      //   languages.findIndex(language => language === getLang()) || 0
      // )
    )
  }, [])
}

export default useLanguage
