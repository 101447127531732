import { useEffect } from 'react'
import useSettingsQuery from './useSettingsQuery'

function useCssVariables() {
  const settings = useSettingsQuery()

  useEffect(() => {
    let vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty(
      '--splash-bg-color',
      settings.splashBgColor
    )
    document.documentElement.style.setProperty(
      '--main-bg-color',
      settings.mainBgColor
    )
    document.documentElement.style.setProperty(
      '--splash-text-color',
      settings.splashTextColor
    )
    document.documentElement.style.setProperty(
      '--main-text-color',
      settings.mainTextColor
    )
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }, [])
}

export default useCssVariables
